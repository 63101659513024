import React from "react";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      // <Layout>

      <form
        name="quote-request"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        className="quote-form container"
      >
        <h1 className="is-size-4 has-text-centered" style={{ color: "black" }}>
          Quote Request
        </h1>
        <div className="row is-full">
          <hr
            style={{
              color: "#E7222E",
              border: "1px solid",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        </div>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="quote-request" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>

        <div className="row is-full" style={{ marginBottom: "20px" }}>
          <div className="columns">
            <div className="column is-4">
              <label className="label" htmlFor={"name"}>
                Name
              </label>
              <div className="control">
                <input
                  className="input"
                  type={"text"}
                  name={"name"}
                  onChange={this.handleChange}
                  id={"name"}
                  required={true}
                />
              </div>
            </div>
            <div className="column is-4">
              <label className="label" htmlFor={"phone"}>
                Phone
              </label>
              <div className="control">
                <input
                  className="input"
                  type={"text"}
                  name={"phone"}
                  onChange={this.handleChange}
                  id={"phone"}
                  required={true}
                />
              </div>
            </div>
            <div className="column is-4">
              <label className="label" htmlFor={"email"}>
                Email
              </label>
              <div className="control">
                <input
                  className="input"
                  type={"email"}
                  name={"email"}
                  onChange={this.handleChange}
                  id={"email"}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row is-full">
          <hr
            style={{
              color: "#E7222E",
              border: "1px solid",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        </div>
        <div className="row is-full">
          <div className="columns">
            <div className="column is-4">
              <label className="label" htmlFor={"journey-type"}>
                Journey Type
              </label>
              <select
                style={{
                  boxShadow: "inset 0 0.0625em 0.125em rgb(43 37 35 / 5%)",
                  maxWidth: "100%",
                  width: "100%",
                  borderColor: "#dbdbdb",
                  borderRadius: "4px",
                  color: "grey",
                  padding: "5px",
                  fontSize: "1rem",
                  height: "2.5em",
                }}
                name="journey-type"
                id="journey-type"
                onChange={this.handleChange}
                required={true}
              >
                <option value="one-way">One Way</option>
                <option value="same-day-return">Same Day Return</option>
                <option value="different-day-return">
                  Different Day Return
                </option>
                <option value="other/complex">Other/Complex</option>
              </select>
            </div>
          </div>
        </div>
        <div className="columns">
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="message" onChange={this.handleChange} />
            </label>
          </div>
          {this.state["journey-type"] === "other/complex" ? (
            <div className="column is-4">
              <label className="label" htmlFor={"message"}>
                Message
              </label>
              <textarea
                style={{
                  resize: "none",
                  boxShadow: "inset 0 0.0625em 0.125em rgb(43 37 35 / 5%)",
                  maxWidth: "100%",
                  width: "100%",
                  borderColor: "#dbdbdb",
                  borderRadius: "4px",
                  color: "#363636",
                  padding: "5px",
                  fontSize: "1rem",
                }}
                // className="input"
                name={"message"}
                onChange={this.handleChange}
                id={"message"}
                required={true}
                rows="10"
                cols="65"
                placeholder="Enter journey details"
              />
              {/* <textarea id="message" name="message" onChange={this.handleChange}></textarea> */}
            </div>
          ) : (
            <>
              <div className="column is-2">
                <label className="label" htmlFor={"pickup-date"}>
                  Pickup Date
                </label>
                <div className="control">
                  <input
                    className="input"
                    style={{color:"grey"}}
                    type={"date"}
                    name={"pickup-date"}
                    onChange={this.handleChange}
                    id={"pickup-date"}
                    required={true}
                  />
                </div>
              </div>
              <div className="column is-2">
                <label className="label" htmlFor={"pickup-time"}>
                  Pickup Time
                </label>
                <div className="control">
                  <input
                    className="input"
                    style={{color:"grey"}}
                    type={"time"}
                    name={"pickup-time"}
                    onChange={this.handleChange}
                    id={"pickup-time"}
                    required={true}
                  />
                </div>
              </div>
              <div className="column is-4">
                <label className="label" htmlFor={"pickup-address"}>
                  Pickup Address
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"pickup-address"}
                    onChange={this.handleChange}
                    id={"pickup-address"}
                    required={true}
                    style={{color:"grey"}}
                  />
                </div>
              </div>
              <div className="column is-4">
                <label className="label" htmlFor={"destination-address"}>
                  Destination Address
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"destination-address"}
                    onChange={this.handleChange}
                    id={"destination-address"}
                    required={true}
                    style={{color:"grey"}}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="return-time" onChange={this.handleChange} />
          </label>
        </div>
        {this.state["journey-type"] === "same-day-return" && (
          <div className="row is-full">
            <div className="columns">
              <div className="column is-2"></div>
              <div className="column is-2">
                <label className="label" htmlFor={"return-time"}>
                  Return Time
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"time"}
                    name={"return-time"}
                    onChange={this.handleChange}
                    id={"return-time"}
                    required={true}
                    style={{color:"grey"}}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="return-date" onChange={this.handleChange} />
          </label>
        </div>
        {this.state["journey-type"] === "different-day-return" && (
          <div className="row is-full">
            <div className="columns">
              <div className="column is-2">
                <label className="label" htmlFor={"return-date"}>
                  Return Date
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"date"}
                    name={"return-date"}
                    onChange={this.handleChange}
                    id={"return-date"}
                    required={true}
                    style={{color:"grey"}}
                  />
                </div>
              </div>
              <div className="column is-2">
                <label className="label" htmlFor={"return-time"}>
                  Return Time
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"time"}
                    name={"return-time"}
                    onChange={this.handleChange}
                    id={"return-time"}
                    required={true}
                    style={{color:"grey"}}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state["journey-type"] !== "other/complex" && (
          <div className="row is-full">
            <div className="columns">
              <div className="column is-2">
                <label className="label" htmlFor={"passengers"}>
                  Number of Passengers
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"passengers"}
                    onChange={this.handleChange}
                    id={"passengers"}
                    required={true}
                    style={{color:"grey"}}
                  />
                </div>
              </div>
              <div className="column is-6">
                <label
                  className="label"
                  htmlFor={"luggage-and-additional-info"}
                >
                  Luggage Requirements & Additional Information
                </label>
                <textarea
                  style={{
                    resize: "none",
                    boxShadow: "inset 0 0.0625em 0.125em rgb(43 37 35 / 5%)",
                    maxWidth: "100%",
                    width: "100%",
                    borderColor: "#dbdbdb",
                    borderRadius: "4px",
                    color: "grey",
                    padding: "5px",
                    fontSize: "1rem",
                  }}
                  // className="input"
                  name={"luggage-and-additional-info"}
                  onChange={this.handleChange}
                  id={"luggage-and-additional-info"}
                  required={true}
                  rows="5"
                  cols="65"
                  placeholder="Enter your luggage requirements and any additional information"
                />
              </div>
            </div>
          </div>
        )}
        <div className="row is-full">
          <hr
            style={{
              color: "#E7222E",
              border: "1px solid",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        </div>
        <div className="quote-button" style={{margin: "0 auto"}}>
          <button
            className="button is-link"
            type="submit"
            style={{ width: "100%" }}
          >
            Request Quote
          </button>

          {/* <div className="column is-2">
            <button
              className="button is-link"
              style={{ backgroundColor: "#E7222E", width: "100%" }}
              onClick={() => this.props.toggleShowQuoteForm(false)}
            >
              Cancel
            </button>
          </div> */}
        </div>
      </form>
      // </Layout>
    );
  }
}
